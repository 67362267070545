import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Landing from './components/landingPage';
import NotFoundPage from './components/notFoundPage';
import About from './components/aboutPage';
const router = createBrowserRouter([
  {
    path: '/',
    element: <Landing />,
    errorElement: <NotFoundPage />,
  },
  {
     path:'/myblog',
     element: <Landing />,
  },
  {
    path:'/about',
    element: <About />,
  }
]);
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
